// Custom Bootstrap navtabs
@import '../autoload';

// Tabs
.nav-tabs {
  border-bottom: 1px solid $blue;
  margin-bottom: 15px;

  @media #{$mobile} {
    display: flex;
    justify-content: space-between;
  }

  li {
    color: $grey;
    font-size: 1.25rem;

    &:hover a {
      background: none;
      color: $blue;
    }

    a {
      background: none;
      border: none;
      color: inherit;
      padding: 0;
      padding-bottom: 10px;
      position: relative;
      transition: .5s color;
    }

    &.active {
      a,
      a:hover,
      a:focus {
        background: none;
        border: none;
        color: $blue;
        font-weight: bold;
      }
    }

    @media #{$desktop} {
      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }
  }
}

// Content
.tab-pane {
  border: none;
}
