@import 'autoload';

// Modules
@import 'modules/manufacturers';
@import 'modules/navtabs';

@media #{$smartphone} {
  .banners {
    display: none;
  }
}
