// Manufacturers
@import '../autoload';

.manufacturers {
  background-color: $light-grey;
  position: relative;

  @media #{$mobile} {
    display: none;
  }

  .manufacturers__content {
    .owl-carousel {
      padding-left: 45px;
      padding-right: 45px;

      .owl-item {
        padding-bottom: 15px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 15px;
        text-align: center;
      }

      .owl-buttons {
        background: none;
        padding: 0;
        position: static;
        top: 0;
        width: 100%;

        .owl-prev,
        .owl-next {
          background: none;
          color: $purple;
          font-size: 30px;
          position: absolute;
          top: calc(50% - 15px);
        }

        .owl-prev {
          left: 5px;
        }

        .owl-next {
          right: 5px;
        }
      }
    }

    img {
      filter: grayscale(1);
      height: 50px;
      opacity: .7;
      transition: opacity .5s, filter .5s;

      &:hover {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }
}
